import { button } from "@/helpers/wc/buttonSettings";
import { input } from "@/helpers/wc/inputSettings";
import { image } from "@/helpers/wc/imageSettings";
import { video } from "@/helpers/wc/videoSettings";
import { text } from "@/helpers/wc/textSettings";
import { employeeListSettings } from "@/helpers/wc/employeeListSettings";
import { queueVisitorsList } from "@/helpers/wc/queueVisitorsListSettings";
import { queueOperatedVisitor } from "@/helpers/wc/queueOperatedVisitorSettings";
import { langSwitch } from "@/helpers/wc/langSwitcherSettings";
import { keyBoard } from "@/helpers/wc/keyBoardSettings";
import { panel } from "@/helpers/wc/panelSettings";
import { iframe } from "@/helpers/wc/iFrameSettings";
import { checkBox } from "@/helpers/wc/checkBoxSettings";
import { departmentsSettings } from "@/helpers/wc/departmentsSettings";
import { departmentUsersSettings } from "@/helpers/wc/departmentUsersSettings";
import { infoSearchCategoryContentSettings } from "@/helpers/wc/infoSearchCategoryContentSettings";
import { infoSearchSubCategoriesButtonsSettings } from "@/helpers/wc/infoSearchSubCategoriesButtonsSettings";
import { stopPointTimingSettings } from "@/helpers/wc/stopPointTimingSettings";
import { clockSettings } from "@/helpers/wc/clockSettings";
import { responseMessageSettings } from "@/helpers/wc/responseMessageSettings";
import { deceasedListSettings } from "@/helpers/wc/deceasedListSettings";
import { graveyardSettings } from "@/helpers/wc/graveyardSettings";
import { infoSearchPatientsSettings } from "@/helpers/wc/infoSearchPatientsSettings";
import { visitorsListSettings } from "@/helpers/wc/visitorsListSettings";
import { visitorsCustomListSettings } from "@/helpers/wc/visitorsCustomListSettings";
import { openHoursSettings } from "./openHoursSettings";
import { logoSettings } from "./logoSettings";
import { redirectSettings } from "./redirectSettings";
import { talkSettings } from "./talkSettings";
import { eventsCalendarSettings } from "./eventsCalendarSettings";
import { deceasedListGravarSettings } from "./deceasedListGravarSettings";

// default components register
export default [
  button,
  input,
  image,
  video,
  text,
  employeeListSettings,
  visitorsListSettings,
  visitorsCustomListSettings,
  queueVisitorsList,
  queueOperatedVisitor,
  langSwitch,
  keyBoard,
  panel,
  iframe,
  checkBox,
  departmentsSettings,
  departmentUsersSettings,
  infoSearchCategoryContentSettings,
  infoSearchSubCategoriesButtonsSettings,
  stopPointTimingSettings,
  clockSettings,
  openHoursSettings,
  responseMessageSettings,
  deceasedListSettings,
  graveyardSettings,
  infoSearchPatientsSettings,
  logoSettings,
  redirectSettings,
  talkSettings,
  eventsCalendarSettings,
  deceasedListGravarSettings
];
